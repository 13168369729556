<div class="parent-container" [ngClass]="{'compact': compact,  'vmfest-font' : isVMFest}">

    <div class="top-container">
        <div class="search-container">
            <input type="text" #searchInput (focus)="onSearchFocusAndBlur()" (blur)="onSearchFocusAndBlur()"
                [formControl]="searchControl" />
            <button class="clear" *ngIf="searchInput.value?.length > 0" (click)="clear()" mat-icon-button>
                <mat-icon fxHide.lt-md fxShow.gt-sm>clear</mat-icon>
                <mat-icon fxHide.gt-sm fxShow.lt-md>highlight_off</mat-icon>
            </button>
        </div>
        <app-image-carrousel  *ngIf="!compact && !isVMFest" [images]="images"></app-image-carrousel>
        <app-image-carrousel  fxHide.lt-md fxShow.gt-sm *ngIf="!compact && isVMFest" [images]="imagesVMFest"></app-image-carrousel>
        <app-image-carrousel  fxHide.gt-sm fxShow.lt-md *ngIf="!compact && isVMFest" [images]="imagesVMFestMobile"></app-image-carrousel>
    </div>

    <div id="top" class="middle-container" *ngIf="!compact && superCategories?.length > 0" fxLayout="row wrap"
        fxLayoutAlign="center center" fxLayoutGap="30px" fxLayoutGap.lt-md="15px">
        <a routerLinkActive="active-link"
            [routerLink]="[isVMFest ? '/vm-fest': '/', 'category', ('all-events' | translate)]" fragment="top">{{'All events' | translate}}</a>
        <a routerLinkActive [ngClass]="{ 'active-link' : rla.isActive || superCategory?.id == category?.id }"
            #rla="routerLinkActive" *ngFor="let category of superCategories"
            [routerLink]="[isVMFest ? '/vm-fest': '/', 'category', category.slug]" fragment="top">{{category.name}}</a>
        <a routerLinkActive="active-link" [routerLink]="[isVMFest ? '/vm-fest': '/', 'category', ('other' | translate)]"
            fragment="top">{{'Other' | translate}}</a>
        <a routerLinkActive="active-link" *ngIf="!isVMFest" class="vm-category" routerLink="/vm-fest"
            fragment="top">{{'VM-Fest 2025' | translate}}</a>
        <a routerLinkActive="active-link" *ngIf="isVMFest" class="vm-nettside" target="_blank"
            href="https://trondheim2025.no/" fragment="top">{{'Ski-VM 2025' | translate}}</a>
    </div>

    <div *ngIf="featuredEvents?.length > 0">
        <div class="bottom-container">
            <div class="top" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <h2 *ngIf="!isVMFest">{{'Tips for things to do in Trondheim' | translate}}:</h2>
                    <h2 *ngIf="isVMFest">{{'Featured events' | translate}}:</h2>
                </div>
                <div>
                    <form [formGroup]="dateRangeForm" class="inputs-container">
                        <mat-date-range-input class="dateinputs" [rangePicker]="picker">
                            <input matStartDate formControlName="fromDate" [placeholder]="'From date' | translate">
                            <input matEndDate formControlName="untilDate" [placeholder]="'To date' | translate">
                        </mat-date-range-input>
                        <mat-date-range-picker xPosition="below" #picker>
                            <mat-datepicker-actions>
                                <button mat-button matDatepickerCancel>{{'Cancel' | translate}}</button>
                                <button mat-raised-button color="primary" matDatepickerApply>{{'Apply' |
                                    translate}}</button>
                            </mat-datepicker-actions>
                        </mat-date-range-picker>
                    </form>
                    <button fxLayout="row" fxLayoutAlign="space-between center"
                        (click)="picker.open()"><mat-icon>today</mat-icon>{{'Date filter' | translate}}</button>
                </div>
            </div>



            <div class="full-width" fxLayout="row wrap" fxLayoutAlign="space-between start"
                fxLayoutAlign.lt-md="start start">
                <app-event-tile fxFlex="32%" fxFlex.lt-md="100%" *ngFor="let event of featuredEvents" [event]="event"
                    [isVMFest]="isVMFest"></app-event-tile>
            </div>
        </div>
    </div>

    <div *ngIf="isVMFest" class="vm-add-your-event" fxLayout="column" fxLayoutAlign="center center">
        <div class="add-jumbo" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="30px">
            <img src="../../assets/logos/vmfest-logo-vertical-color.png"/>
            <a href="https://trdevents.no/add-an-event" target="_blank">{{'Add your event' | translate}}</a>
        </div>
        <div class="disclaimer">{{'VM-fest is an umbrella for cultural events during the 2025 FIS Nordic World Ski Championships, organized by individual event organizers. The 2025 Ski World Championships is not responsible for content outside of events organized by its own management.' | translate}}</div>
    </div>

    <div class="bottom-container-space" *ngIf="featuredEvents?.length > 0">
        <h2 *ngIf="featuredEvents?.length > 0 && !isVMFest">{{'Check out all the events in Trondheim' | translate}}:
        </h2>
        <h2 *ngIf="featuredEvents?.length > 0 && isVMFest">{{'Check out the whole VM-Fest' | translate}}:</h2>
    </div>

    <div [ngClass]="featuredEvents?.length > 0 ? 'events' : 'bottom-container'">
        <app-frontpage-events [isVMFest]="isVMFest"></app-frontpage-events>
    </div>
</div>